<template>
  <div class="p-3">
    <div class="monitoring-content__chart">
      <ChartBarMonitoring :seriesProp="dataMonitoringByFoiv" :nn="nnByFoiv" />
    </div>
    <ButtonStock class="chart-stacked__btn" title="Подробная информация" @click="goTo" />
  </div>
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ChartBarMonitoring from '@/components/ChartComponents/ChartBarMonitoring';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget17',
    components: {
      ButtonStock,
      ChartBarMonitoring,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadPageByFoiv();
      },
    },
    methods: {
      goTo() {
        this.$router.push({ name: 'Assignments', query: { countryId: this.countryId } });
      },
      loadPageByFoiv() {
        API.getTotalByFoiv({ countryId: this.countryId }).then((response) => {
          this.dataMonitoringByFoiv = [];
          this.totalDataByFoiv = {};
          if (response.data && response.data.length > 0) {
            this.totalDataByFoiv = response.data[0];
            response.data.sort((a, b) => b.totalCount - a.totalCount);
            response.data.slice(1).forEach((el) => {
              this.dataMonitoringByFoiv.push({
                name: el.name,
                data: [el.done, el.inWork, el.notExecute],
              });
            });
            this.nnByFoiv++;
          }
        });
      },
    },
    data() {
      return {
        nnByFoiv: 1,
        dataMonitoringByFoiv: [],
        totalDataByFoiv: {},
      };
    },
    created() {
      this.loadPageByFoiv();
    },
  };
</script>

<style lang="scss" scoped></style>
